.App {
  text-align: center;
}
.tooltip{
  position: absolute;
  background-color: #75d8d5;
  z-index: 100;
  pointer-events: none;
  padding: 10px;
  opacity: .8;
  font-family: avenir next,avenir,sans-serif;
}
.faCustom {
  display: inline-block !important ;
  font: normal normal normal 16px/1 FontAwesome !important;
  /*font-size: 16px !important;*/
  text-rendering: auto !important;
  -webkit-font-smoothing: antialiased !important ;
  -moz-osx-font-smoothing: grayscale !important;
}
.tweetStats{
  position: absolute;
  bottom: 16px;
}
.scalevals{
  display: flex;
}
.itemTitle{
  padding-top: 5px;
  display: inline-block;
}
.item{
  margin-top: 5px;
}
.bd-color{border-radius:2px; float: right; box-shadow:0 2px 3px 0 rgba(0,0,0,.1),inset 0 0 0 1px rgba(0,0,0,.1);height:24px;width:24px; margin-left: 24px;}
.legend{
  position: absolute;
  bottom: 35px;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  background-color:   #75d8d5;
  left: 8px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  border-radius: 2px;
  width: 200px;
}
li {
list-style-type: none;
}
.legend > :first-child { margin-top: 0 !important; }
.scaleMin{
  float: left;
}
.scaleMax{
  float: right;
}
.scaleval{
  flex-grow: 100;
  height: 20px;
}
.layerPicker li {
    background: #ff3232;
    /* Old browsers */

}
.skillsDouble li:hover {
    background-position:right bottom;
}


.transitions-item {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 5em;
  font-weight: 800;
  text-transform: uppercase;
  will-change: transform, opacity, height;
  white-space: nowrap;
  cursor: pointer;
  line-height: 80px;
}
.tweet{
  padding: 12px 24px;
  height: 355px;
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
}
.tweet h3{
  margin: 8px 0;
  font-size: 1.2em;
  font-weight: 500;
}
.storypanel {
  position: absolute;
  z-index: 2;
  top: 25vh;
  right: 4vw;
  width: 300px;
  background:#fff;
  height: 390px;
  display:flex;
  flex-direction:column;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0,0,0,.15);
  font-family: avenir next,avenir,sans-serif !important;
}
.content{
  flex: 1 100%;
  flex-direction: row;
  display: flex;
  overflow: hidden;
}
.pickerLeft{
  z-index: 10;
  border: 1px solid black;
  color: black;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: transparent;
}
.selected{
  background: #09979b;
  width: 30px;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.pickerPlay{
  position: relative;
  margin-left: 10px;
  margin-top: 2px;
  color: black;
}
.selected.left {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.selected.right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.pickerMiddle{
  z-index: 10;
  border: 1px solid black;
  border-left: 0px;
  color: black;
  background: transparent;
}
.pickerRight{
  z-index: 10;
  border: 1px solid black;
  color: black;
  border-left: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background: transparent;
}
.pickers{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: auto;
    padding: 10px;
    padding-bottom: 0px;
    display: inline-flex;
}
.back{
  fill:currentcolor;
  border-right: 1px solid black !important;
  border-top:  1px solid black !important;
  transition: 0.3s;
}
.next{
  fill:currentcolor;
  border-top:  1px solid black !important;
  transition: 0.3s;
}

.button-disabled {
  opacity: 0.6;
  pointer-events: none;
}

button[disabled] {
    cursor : not-allowed !important;
    pointer-events: none
}

.back:hover {
  background-color: #09979b;
}

.next:hover {
  background-color: #09979b;
}

.storybuttons{
  display: flex;
}
input[type=range] {
  -webkit-appearance: none;
  width: 80% !important;
  height: 13px;
  margin: 11px 0;
  margin-bottom: 0px;
}
input[type=range]:focus {
  outline: none;
}
.playPause{
  margin-left: 5px;
  border: 0px;
  color: black;
  background: transparent;
}
.sliders{
    margin: 5px;
    width: auto;
    padding: 2px;
    padding-bottom: 0px;
}
.sliderValue{
  margin: auto;
  width: auto;
  margin-left: 37%;
  font-weight: 600;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 20px;
  height: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: black;
  border-top: 9px solid #fff;
  border-bottom: 9px solid #fff; 
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 23px;
  width: 5px;
  border-radius: 0px;
  background: #09979b;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: black;
}
input[type=range]::-moz-range-track {
   width: 20px;
  height: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: black;
  border-top: 9px solid #fff;
  border-bottom: 9px solid #fff; 
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 23px;
  width: 5px;
  border-radius: 0px;
  background:#09979b;
  border-width: 20px;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
   width: 20px;
  height: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: black;
  border-top: 9px solid #fff;
  border-bottom: 9px solid #fff; 
}
input[type=range]::-ms-fill-upper {
  width: 20px;
  height: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: black;
  border-top: 9px solid #fff;
  border-bottom: 9px solid #fff; 
}
input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 23px;
  width: 5px;
  border-radius: 0px;
  background: #09979b;
  cursor: pointer;
  height: 1px;
}
input[type=range]:focus::-ms-fill-lower {
  background: black;
}
input[type=range]:focus::-ms-fill-upper {
  background: black;
}


